
import {
  BFormFile,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import { BFormRadio } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { BFormDatepicker } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import useProfile from "./useProfile";
import { IUser } from "@core/services/interfaces/user/IUserService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { AvButton } from "@/components";

// Services
import { useGroupEconomic } from "@/services";

// Interfaces
import { IResponseListGroupEconomic } from "@core/services/interfaces/business/group-economic/IGroupEconomicService";

@Component({
  name: "profileData",
  components: {
    AvButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    BFormRadio,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
})
export default class Profile extends Vue {
  groupTitle =
    this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE ||
    "Grupo Empresa";
  economicGroup: { value: number | null; label: string | null } = {
    value: null,
    label: null,
  };
  economicGroupOptions: Array<{ value: number; label: string }> = [];
  profileOptions: Array<{ value: number; label: string }> = [];
  groupOptions: Array<{ value: number; label: string }> = [];
  dealershipsOptions: Array<{ value: string; label: string }> = [];
  user = {} as IUser;
  saving = false;
  showEconomicGroup: boolean = useGroupEconomic.verifyEconomicGroupPermission();
  userLocal = {} as {
    id: string;
    name: string;
    login: string;
    email: string;
    cpfCnpj: string;
    dealerships: string[];
    economicGroup: any;
    receiveDailyReport: boolean;
    status: boolean;
    profile: { label: string; value: string };
    insertedBy: string;
    updatedBy: string;
  };

  useProfile = useProfile(this);
  formValidation = formValidation();

  // Computed
  get isSaving(): boolean {
    return this.saving;
  }

  // Lifecycle
  created() {
    if (this.showEconomicGroup) {
      useGroupEconomic
        .requestGet()
        .then((response: IResponseListGroupEconomic) => {
          this.economicGroupOptions = response.data.data.map(
            (groupEconomic) => ({
              value: groupEconomic.Id,
              label: groupEconomic.Nome,
            })
          );
        });
    }

    this.useProfile
      .fetchListProfile()
      .then((data: Array<{ value: number; label: string }>) => {
        this.profileOptions = data;
      });

    this.useProfile
      .fetchListGroups()
      .then((data: Array<{ value: number; label: string }>) => {
        this.groupOptions = data;
      });

    this.useProfile
      .fetchListDealerships()
      .then((data: Array<{ value: string; label: string }>) => {
        this.dealershipsOptions = data;
      });

    this.useProfile.fetchGetLoggedUser().then((data: IUser) => {
      this.user = data;
      const dateInc: string = data.Control_DatInc.split("T")[0];
      const timeInc: string = data.Control_DatInc.split("T")[1];
      const dateUp: string = data.Control_DatAlter.split("T")[0];
      const timeUp: string = data.Control_DatAlter.split("T")[1];

      this.userLocal = {
        id: data.Id,
        name: data.Nome,
        login: data.Login,
        email: data.Email,
        cpfCnpj: data.CpfCnpj,
        dealerships: data.ConcessionariasIds,
        economicGroup: null,
        receiveDailyReport: data.ReceberBoletimDiario,
        status: data.Ativo,
        profile: { label: data.PerfilNome, value: data.PerfilId },
        insertedBy: `${data.Control_UsuInc} - ${dateInc.split("-")[2]}/${
          dateInc.split("-")[1]
        }/${dateInc.split("-")[0]} ${timeInc.split(".")[0]}`,
        updatedBy: `${data.Control_UsuAlter} - ${dateUp.split("-")[2]}/${
          dateUp.split("-")[1]
        }/${dateUp.split("-")[0]} ${timeUp.split(".")[0]}`,
      };
    });
  }

  // Methods
  onSubmit(): void {
    this.saving = true;
    this.useProfile.fetchUpdateUser(this.userLocal).finally(() => {
      this.saving = false;
    });
  }

  reduceOptionValue = (option: { label: string; value: any }): any => {
    return option.value;
  };

  setDealerships(options: { label: string; value: number }) {
    return options.value;
  }
}
