
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { IResponseChangePassword } from "@core/services/interfaces/administrator/IAdministratorService";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useAdministrator } from "@/services";

@Component({
  name: "ProfileSettingPassword",
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BFormInvalidFeedback,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    AvButton,
  },
  directives: {
    Ripple,
  },
})
export default class ProfileSettingPassword extends Vue {
  // Data
  passwordValueOld: string = "";
  newPasswordValue: string = "";
  retypePassword: string = "";
  passwordFieldTypeOld: string = "password";
  passwordFieldTypeNew: string = "password";
  passwordFieldTypeRetype: string = "password";
  saving = false;

  formValidation = formValidation();

  // Computeds
  get isSaving(): boolean {
    return this.saving;
  }

  get passwordToggleIconOld(): string {
    return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
  }

  get passwordToggleIconNew(): string {
    return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
  }

  get passwordToggleIconRetype(): string {
    return this.passwordFieldTypeRetype === "password"
      ? "EyeIcon"
      : "EyeOffIcon";
  }

  get isNewPasswordSame(): boolean | null {
    if (this.newPasswordValue == "" && this.retypePassword == "") return null;

    return this.newPasswordValue === this.retypePassword;
  }

  // Methods
  togglePasswordOld(): void {
    this.passwordFieldTypeOld =
      this.passwordFieldTypeOld === "password" ? "text" : "password";
  }

  togglePasswordNew(): void {
    this.passwordFieldTypeNew =
      this.passwordFieldTypeNew === "password" ? "text" : "password";
  }

  togglePasswordRetype(): void {
    this.passwordFieldTypeRetype =
      this.passwordFieldTypeRetype === "password" ? "text" : "password";
  }

  onSubmit(): void {
    this.saving = true;
    useAdministrator
      .requestChangePassword({
        senha: this.passwordValueOld,
        novaSenha: this.newPasswordValue,
      })
      .then((response: IResponseChangePassword) => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Senha atualizada com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao atualizar a senha, verifique se a senha atual corresponde e tente novamente!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.saving = false;
      });
  }
}
