
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
import ProfileTabInfo from "./ProfileTabInfo.vue";
import ProfileTabChangePassword from "./ProfileTabChangePassword.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ProfilePage",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormInput,
    ProfileTabInfo,
    ProfileTabChangePassword,
  },
})
export default class ProfilePage extends Vue {
  tabIndex = 0;
}
